// Page specific code

HomingPIN.addPage('account_subscription', function() {

    var form = document.querySelector('#changeCardForm');
    var submit = document.querySelector('#changeCardBtn');

    var basicBillingForm = braintree.client.create({
      // Replace this with your own authorization.
      authorization: document.head.querySelector("[name=client_token]").content
    }, function (clientErr, clientInstance) {
      if (clientErr) {
        return;
      }
      braintree.hostedFields.create({
        client: clientInstance,
        styles: {
          'input': {
            "font-size": "14px",
          },
          'input.invalid': {
            'color': 'red'
          },
          'input.valid': {
            'color': 'green'
          }
        },
        fields: {
          number: {
            selector: '#card-number'
          },
          cvv: {
            selector: '#cvv'
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YYYY'
          }
        }
      }, function (hostedFieldsErr, hostedFieldsInstance) {
        if (hostedFieldsErr) {
          return;
        }
        submit.removeAttribute('disabled');

        form.addEventListener('submit', function (event) {
          event.preventDefault();

          hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
            if (tokenizeErr) {
              return;
            }

            // Put `payload.nonce` into the `payment-method-nonce` input, and then
            // submit the form. Alternatively, you could send the nonce to your server
            // with AJAX.
            document.querySelector('input[name="payment-method-nonce"]').value = payload.nonce;
            form.submit();
          });
        }, false);
      });
    });
});
