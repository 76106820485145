// Page specific code

HomingPIN.addPage('checkout', function() {

    var localeCode = $('meta[name="locale"]').attr('content');

    $('.quantity-select').change(function(){
    const targetted = $(this),
          parentRow = targetted.parents('.basket-line'),
          rowId = parentRow.data('id'),
          value = targetted.val();

    if (targetted.data('sync')) {
        $(targetted.data('sync')).val(value);
    }

    parentRow.find('.quantity-select.' + rowId).val(value);
    });

    $('#deliverySelect').on('change', function (e) {
        var option = $(this).val();
        var totalText = $('#totalText').text();
        $('#totalText').text('Updating...');
        $.ajax({
            url: '/' + localeCode + '/checkout/update-delivery/' + option,
            type: 'GET'
        })
        .done(function(response) {
            if (response.no_delivery) {
                $('#noDeliveryMessage').removeClass('hidden');
                $('#makePaymentBtn').attr('disabled', true);
                $('#totalText').text(totalText);
            } else {
                $('#noDeliveryMessage').addClass('hidden');
                $('#makePaymentBtn').attr('disabled', false);
                $('#totalText').text(response.total);
            }
        });
    });

    $('#country').on('changed.bs.select', function (event) {

        var country = $(this).val();

        $.ajax({
            url: '/' + localeCode + '/checkout/delivery-options.js',
            type: 'GET',
            data: {
                country: country
            },
        })
        .done(function(response) {
            if (!response.length) {
                $('#noDeliveryMessage').removeClass('hidden');
                $('#deliverySelect').addClass('hidden');
                $('#makePaymentBtn').attr('disabled', true);
            } else {
                var options = $('#deliverySelect');
                $('#noDeliveryMessage').addClass('hidden');
                $('#deliverySelect').removeClass('hidden');
                $('#makePaymentBtn').attr('disabled', false);
                options.empty(); // remove old options
                $.each(response, function(index, option) {
                    $('#deliverySelect').append($("<option></option>")
                     .attr("value", option.id)
                     .attr('selected', option.default)
                     .text(option.description));

                    if (option.default) {
                        $.ajax({
                            url: '/' + localeCode + '/checkout/update-delivery/' + option.id,
                            type: 'GET'
                        })
                        .done(function(response) {
                            if (response.no_delivery) {
                                $('#noDeliveryMessage').removeClass('hidden');
                                $('#totalText').text(totalText);
                            } else {
                                $('#noDeliveryMessage').addClass('hidden');
                                $('#totalText').text(response.total);
                            }
                        });
                    }
                });
            }
        });
    });
});

