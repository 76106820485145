HomingPIN.addPage('quick_activation', function() {

    $('#addHomingPinBtn').click(function(e){
        const source = $('#newHomingPin').html(),
            template = Hogan.compile(source),
            count = $('#homingpins').find('.form-group').length,
            data = {
                index: (count + 1)
            },
            output = template.render(data);
        $('#homingpins').append(output);
    });

    $("form").submit(function() {
        var numbers = $(this).find('.telephone');
        numbers.each(function(index, number){
            if ($(number).intlTelInput("getNumber")) {
                $(number).val($(number).intlTelInput("getNumber"));
            }
        });
    });

    $('#homingpins').on('click', '.btn-delete', function(event) {
    const target = '#' + $(this).data('row');
    $(target).remove();
    });

    const input = $(".telephone");
    const preselected = input.data('preselected');

    var telOptions = {
        nationalMode: true,
        utilsScript: "/js/vendor/intl-tel-input/js/utils.js"
    };

    if (preselected == 'canada') {
        telOptions['defaultCountry'] = 'ca';
        telOptions['preferredCountries'] = ['ca', 'gb'];
    } else {
        telOptions['defaultCountry'] = 'us';
        telOptions['preferredCountries'] = ['gb','us'];
    }

    input.intlTelInput(telOptions);

    input.change(function (e) {
        // Get the option from the country dropdown
        var info = input.intlTelInput('getSelectedCountryData');

        $("input[name='country_iso']").val(info.iso2);

        // // Get the country
        // $('#country option:contains(' + info.name + ')').each(function(){
        //     if ($(this).text() == info.name) {
        //         $(this).attr('selected', 'selected');
        //         return false;
        //     } else {
        //         $(this).removeAttr('selected');
        //     }
        //     return true;
        // });

        // $('#country').selectpicker('refresh');

    });

    $('#marketingCheckbox').change(function (e) {
        $('#sendReportBtn').attr('disabled', !$(this).is(':checked'));
    });
});