HomingPIN.addPage('admin_reports', function() {

    var dataTable = $('#adminReports').DataTable({
        "pageLength": 10,
        "responsive": true,
        "language": {
            "paginate": {
              "previous": '<i class="fa fa-angle-left"></i>',
              "next": '<i class="fa fa-angle-right"></i>'
            }
        },
        'order': [[5, 'desc']],
        "columnDefs": [
            { "name": "id",   "targets": 0 },
            { "name": "code",   "targets": 1},
            { "name": "name",   "targets": 2},
            { "name": "email",   "targets": 3},
            { "name": "description",   "targets": 4},
            { "name": "createdAt",   "targets": 5, "render": $.fn.dataTable.render.moment( 'Do MMM YYYY' )}
        ],
        "bProcessing": true,
        "bServerSide": true,
        "ajax": '/en/admin/reports.json'
    });

    $('#adminReports').on('click', 'tbody tr', function(event) {
        var data = dataTable.row(this).data();
        document.location.href = '/en/admin/reports/' + data.id;
    });

});