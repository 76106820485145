 HomingPIN.addPage('edit_partner', function() {
    /**
     * Contact Methods
     */

    $('#addtelephone').on('show.bs.modal', function () {
        $('#addContactErrors').hide();
    });

    $('#addtelephone').on('shown.bs.modal', function () {
        $('#newContactTelephone').focus();
    });

    $('#addTelephoneBtn').on('click', function(e){
        e.preventDefault();

        var el = $(this);

        if (el.data('submitted')) {
            return;
        }

        el.data('submitted', true);

        $('#newContactTelephone').val($('#newContactTelephone').intlTelInput("getNumber"));

        $.ajax({
            url: URL_PREFIX+'/admin/partners/add-contact',
            type: "post",
            dataType: "json",
            data: { partner: el.data('partner'), telephone: $('#newContactTelephone').val() },
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                var errors = $.parseJSON(data.responseText);

                var errorTxt = '';

                $.each(errors, function (key, value) {
                    errorTxt = errorTxt + value + '<br>';
                });

                errorTxt = errorTxt.substring(0, errorTxt.length - 4);

                $('#addTelephoneContactErrors').text(errorTxt);
                $('#addTelephoneContactErrors').show();

                el.removeData('submitted');
            }
        });
    });

    $('#emailAdd').submit(function(e){
        e.preventDefault();

        var el = $(this);

        if (el.data('submitted')) {
            return;
        }

        el.data('submitted', true);


        $.ajax({
            url: URL_PREFIX+'/admin/partners/add-contact',
            type: "post",
            dataType: "json",
            data: { partner: el.data('partner'), email: $('#newContactEmail').val() },
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                var errors = $.parseJSON(data.responseText);

                var errorTxt = '';

                $.each(errors, function (key, value) {
                    errorTxt = errorTxt + value + '<br>';
                });

                errorTxt = errorTxt.substring(0, errorTxt.length - 4);

                $('#addEmailContactErrors').html(errorTxt);
                $('#addEmailContactErrors').show();

                el.removeData('submitted');
            }
        });
    });

    $('#removeEmail').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $('#removeEmailValue').text(invoker.data('value'));
        $('#removeEmailValue2').val(invoker.data('value'));
    });

    $('#removeTelephone').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $('#removeTelephoneValue').text(invoker.data('value'));
        $('#removeTelephoneValue2').val(invoker.data('value'));
    });
});