// Page specific code

HomingPIN.addPage('account_profile', function() {

    /**
     * Profile image upload
     */

    var widget = uploadcare.Widget('#profile-image-uploader');

    $('.uploadcare-widget').hide();

    $('#uploadProfileImage, [data-action="addPhoto"]').click(function(e){
        e.preventDefault();
        var dialog = widget.openDialog('file');
    });

    $('#removeProfileImage').click(function(e){
        e.preventDefault();
        $('#profileImageUUID').val('');
        $('#profileImage').removeAttr('src');
        $('#removeProfileImage').hide();
    });

    widget.onUploadComplete(function(info) {
        // Handle uploaded file info.
        $('#profileImageUUID').val(info.uuid);
        $('#profileImageCrop').val(JSON.stringify(info.crop));

        var imageUrl = info.cdnUrl;

        if (imageUrl.match(/preview\/$/)) {
          imageUrl = imageUrl + '85x85/';
        } else {
          imageUrl = imageUrl + '-/preview/85x85/';
        }

        $('#profileImage').attr('src', imageUrl);
        $('#removeProfileImage').show();
        $('#profileImagePreview').val(imageUrl);
    });

    /**
     * Contact Methods
     */

    $('#addtelephone').on('show.bs.modal', function () {
        $('#addContactErrors').hide();
    });

    $('#addtelephone').on('shown.bs.modal', function () {
        $('#newContactTelephone').focus();
    });

    $('#addTelephoneBtn').on('click', function(e){
        e.preventDefault();

        var el = $(this);

        if (el.data('submitted')) {
            return;
        }

        el.data('submitted', true);

        $('#newContactTelephone').val($('#newContactTelephone').intlTelInput("getNumber"));

        $.ajax({
            url: URL_PREFIX+'/account/profile/add-contact',
            type: "post",
            dataType: "json",
            data: { telephone: $('#newContactTelephone').val() },
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                var errors = $.parseJSON(data.responseText);

                var errorTxt = '';

                $.each(errors, function (key, value) {
                    errorTxt = errorTxt + value + '<br>';
                });

                errorTxt = errorTxt.substring(0, errorTxt.length - 4);

                $('#addTelephoneContactErrors').text(errorTxt);
                $('#addTelephoneContactErrors').show();

                el.removeData('submitted');
            }
        });
    });

    $('#emailAdd').submit(function(e){
        e.preventDefault();

        var el = $(this);

        if (el.data('submitted')) {
            return;
        }

        el.data('submitted', true);

        $.ajax({
            url: URL_PREFIX+'/account/profile/add-contact',
            type: "post",
            dataType: "json",
            data: { email: $('#newContactEmail').val() },
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                var errors = $.parseJSON(data.responseText);

                var errorTxt = '';

                $.each(errors, function (key, value) {
                    errorTxt = errorTxt + value + '<br>';
                });

                errorTxt = errorTxt.substring(0, errorTxt.length - 4);

                $('#addEmailContactErrors').html(errorTxt);
                $('#addEmailContactErrors').show();

                el.removeData('submitted');
            }
        });
    });

    $('#removeEmail').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $('#removeEmailValue').text(invoker.data('value'));
        $('#removeEmailValue2').val(invoker.data('value'));
    });

    $('#removeTelephone').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $('#removeTelephoneValue').text(invoker.data('value'));
        $('#removeTelephoneValue2').val(invoker.data('value'));
    });

    /**
     * Friends & Family
     */

    $('#addFriendModal').on('show.bs.modal', function () {
        $('#addFriendErrors').hide();
    });

    // Image upload
    var widget2 = uploadcare.Widget('#add-friend-uploader');

    $('.uploadcare-widget').hide();

    $('#uploadAddFriendImage').click(function(e){
        e.preventDefault();
        var dialog = widget2.openDialog('file');
    });

    $('#removeAddFriendImage').click(function(e){
        e.preventDefault();
        $('#addFriendImageUUID').val('');
        $('#addFriendImage').removeAttr('src');
        $('#removeAddFriendImage').hide();
    });

    widget2.onUploadComplete(function(info) {
        // Handle uploaded file info.
        $('#addFriendImageUUID').val(info.uuid);
        $('#addFriendImageCrop').val(JSON.stringify(info.crop));

        var imageUrl = info.cdnUrl;

        if (imageUrl.match(/preview\/$/)) {
          imageUrl = imageUrl + '85x85/';
        } else {
          imageUrl = imageUrl + '-/preview/85x85/';
        }

        $('#addFriendImage').attr('src', imageUrl);
        $('#removeAddFriendImage').show();
    });

    $('#friendAddForm').submit(function(e){
        e.preventDefault();

        var el = $(this);


        if (el.data('submitted')) {
            return;
        }

        el.data('submitted', true);

        var friendData = {
            name: $('#friendAddForm input[name=name]').val(),
            image_uuid: $('#friendAddForm input[name=image_uuid]').val(),
            image_crop: $('#friendAddForm input[name=image_crop]').val(),
            email: $('#friendAddForm input[name=email]').val(),
            telephone: $('#friendAddForm input[name=telephone]').val(),
            homingpins: $('#friendAddForm select[name=homingpins]').val()
        };

        $.ajax({
            url: URL_PREFIX+'/account/profile/add-friend',
            type: "post",
            dataType: "json",
            data: friendData,
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                var errors = $.parseJSON(data.responseText);

                var errorTxt = '';

                $.each(errors, function (key, value) {
                    errorTxt = errorTxt + value + '<br>';
                });

                errorTxt = errorTxt.substring(0, errorTxt.length - 4);

                $('#addFriendErrors').html(errorTxt);
                $('#addFriendErrors').show();

                el.removeData('submitted');
            }
        });
    });

    $('#editFriendModal').on('show.bs.modal', function (e) {
        // Get friend data
        var invoker = $(e.relatedTarget);

        var friendId = invoker.data('id');

        $.ajax({
            url: URL_PREFIX+'/account/profile/get-friend',
            type: "get",
            dataType: "json",
            data: { id: friendId },
            async: false,
            success: function (data) {
                // Populate form
                $('#editFriendId').val(friendId);
                $('#editFriendTitle').text(data.friend.name);

                $('#removeFriendBtn').data('id', friendId);
                $('#removeFriendBtn').data('name', data.friend.name);

                $('#editFriendName').val(data.friend.name);
                $('#editFriendEmail').val(data.friend.email);
                $('#editFriendMobile').val(data.friend.mobile);

                $('#editFriendImageUUID').val(data.friend.imageUuid);
                $('#editFriendImageCrop').val(data.friend.imageCrop);
                $('#editFriendImage').attr('src', data.friend.imageUrl);

                if (data.friend.imageUuid && data.friend.imageUuid != null) {
                    $('#removeEditFriendImage').show();
                }

                var email = '';
                var telephone = '';

                $(data.friend.contactMethods).each(function(){
                    if (this.type == 'email') {
                        email = this.value;
                    } else {
                        telephone = this.value;
                    }
                });

                $('#editFriendEmail').val(email);
                $('#editFriendTelephone').val(telephone);

                var homingpins = [];

                $(data.friend.homingpins).each(function(){
                    homingpins.push(this.code);
                });

                $('#editFriendHomingpins').selectpicker('val', homingpins);

                $('#editFriendErrors').hide();
            },
            error: function (data) {
                alert('Unable to load friend data');
                e.preventDefault();
            }
        });

    });

    // Image upload
    var widget3 = uploadcare.Widget('#edit-friend-uploader');

    $('.uploadcare-widget').hide();

    $('#uploadEditFriendImage').click(function(e){
        e.preventDefault();
        var dialog = widget3.openDialog('file');
    });

    $('#removeEditFriendImage').click(function(e){
        e.preventDefault();
        $('#editFriendImageUUID').val('');
        $('#editFriendImage').removeAttr('src');
        $('#removeEditFriendImage').hide();
    });

    widget3.onUploadComplete(function(info) {
        // Handle uploaded file info.
        $('#editFriendImageUUID').val(info.uuid);
        $('#editFriendImageCrop').val(JSON.stringify(info.crop));

        var imageUrl = info.cdnUrl;

        if (imageUrl.match(/preview\/$/)) {
          imageUrl = imageUrl + '85x85/';
        } else {
          imageUrl = imageUrl + '-/preview/85x85/';
        }

        $('#editFriendImage').attr('src', imageUrl);
        $('#removeEditFriendImage').show();
    });


    $('#friendEditForm').submit(function(e){
        e.preventDefault();

        var el = $(this);

        if (el.data('submitted')) {
            return;
        }

        el.data('submitted', true);

        var friendData = {
            id: $('#friendEditForm input[name=id]').val(),
            name: $('#friendEditForm input[name=name]').val(),
            image_uuid: $('#friendEditForm input[name=image_uuid]').val(),
            image_crop: $('#friendEditForm input[name=image_crop]').val(),
            email: $('#friendEditForm input[name=email]').val(),
            telephone: $('#friendEditForm input[name=telephone]').val(),
            homingpins: $('#friendEditForm select[name=homingpins]').val()
        };

        $.ajax({
            url: URL_PREFIX+'/account/profile/edit-friend',
            type: "post",
            dataType: "json",
            data: friendData,
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                var errors = $.parseJSON(data.responseText);

                var errorTxt = '';

                $.each(errors, function (key, value) {
                    errorTxt = errorTxt + value + '<br>';
                });

                errorTxt = errorTxt.substring(0, errorTxt.length - 4);

                $('#editFriendErrors').html(errorTxt);
                $('#editFriendErrors').show();

                el.removeData('submitted');
            }
        });
    });

    $('#removeFriend').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $('#editFriendModal').modal('hide');

        $('#removeFriendValue').text(invoker.data('name'));
        $('#removeFriend input[name=id]').val(invoker.data('id'));
    });

});
