HomingPIN.addPage('access_logs', function() {
    var customerid = $('#customer-id').val();
    var dataTable = $('#accessLogs').DataTable({
        "pageLength": 10,
        "responsive": true,
        "language": {
            "paginate": {
              "previous": '<i class="fa fa-angle-left"></i>',
              "next": '<i class="fa fa-angle-right"></i>'
            }
        },
        "columnDefs": [
            { "name": "id",   "targets": 0 },
            { "name": "firstName",   "targets": 1 },
            { "name": "lastName",   "targets": 2 },
            { "name": "action",   "targets": 3 },
            { "name": "ip",   "targets": 4 },
            { "name": "url",   "targets": 5 },
            { "name": "createdAt",   "targets": 6, "render": $.fn.dataTable.render.moment( 'Do MMM YYYY - HH:MM:SS' )}
        ],
        "bProcessing": true,
        "bServerSide": true,
        "ajax": {
            "url": "/en/admin/customerlogs.json",
            "data": {
                "customer_id": customerid
            }
        }
    });

});