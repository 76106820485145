HomingPIN.addPage('account_travel', function() {

    $('#confirmDeleteInput').keyup(function(event) {
      if ($(this).val() === $(this).data('word')) {
        $('#confirmDeleteBtn').prop('disabled', false);
      } else {
        $('#confirmDeleteBtn').prop('disabled', true);
      }
    });

    $('#deleteTripModal').on('show.bs.modal', function (e) {
        var value = $(e.relatedTarget).data('id'); // Logs the clicked element that opened the modal
        $(e.target).find('input[name=tripId]').val(value);
    });

    // Make if the modal is closed we disable the button and remove the input text
    $('#deleteTripModal').on('hidden.bs.modal', function (e) {
        $('#confirmDeleteBtn').prop('disabled', true);
        $('#confirmDeleteInput').val('');
    })

    $('#sortTravelItems').on('hidden.bs.select', function (e) {
      var selected = $(e.currentTarget).val();
      window.location.href = selected;
    });
});
