// Page specific code

HomingPIN.addPage('account_edit_travel', function() {

    $('#newDestinationBtn').click(function(){
      const source = $('#newDestination').html(),
            destinationCount = $('.trip-itinerary').find('.destination').length,
            destinations = $('.destinations'),
            data = {
              index: (destinationCount + 1)
            },
            template = Hogan.compile(source),
            output = template.render(data);
      destinations.find('.destination.last').removeClass('last');
      destinations.append(output);
      destinations.trigger('refreshdatetimes');
    });

    $('.destinations').trigger('refreshdatetimes');

    $('.destinations').on('refreshdatetimes', function(event){
      $('.travel-date').datepicker({
        format: 'yyyy-m-d'
      });
      $('.depart-date').datepicker({
        format: 'yyyy-m-d'
      });
    });

    $('.travel-date').datepicker({
      format: 'yyyy-m-d'
    });
    $('.depart-date').datepicker({
      format: 'yyyy-m-d'
    }).on('changeDate', function(e) {
        console.log(e);
    });;

    $('.destinations').on('click', '.delete-btn', function(event) {
      $(this).parent('.destination').remove();
    });
});



