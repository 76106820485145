// Page specific code

HomingPIN.addPage('account_homingpin', function() {

    // Upload Care
    var widget = uploadcare.Widget('[role=uploadcare-uploader]');

    $('.uploadcare-widget').hide();

    $('[data-action="addPhoto"]').click(function(e){
        e.preventDefault();
        var dialog = widget.openDialog('file');
    });

    $('#removePhoto').click(function(e){
        e.preventDefault();

        // Save via AJAX
        $.ajax({
            url: URL_PREFIX+'/account/homingpin/update-photo',
            type: "post",
            dataType: "json",
            data: {
                code: $('#homingpinCode').val(),
                uuid: null,
                crop: null
            },
            success: function (data) {
                $('#homingpinPhoto').attr('src', '/img/placeholder/hptag-lg.jpg');
                $('#removePhoto').hide();
                $('#addPhoto').show();
            },
            error: function (data) {

            }
        });
    });

    widget.onUploadComplete(function(info) {
        // Handle uploaded file info.
        var uuid = info.uuid
        var crop = JSON.stringify(info.crop)

        var imageUrl = info.cdnUrl;

        // Save via AJAX
        $.ajax({
            url: URL_PREFIX+'/account/homingpin/update-photo',
            type: "post",
            dataType: "json",
            data: {
                code: $('#homingpinCode').val(),
                uuid: uuid,
                crop: crop
            },
            success: function (data) {
                if (imageUrl.match(/preview\/$/)) {
                    imageUrl = imageUrl + '150x150/';
                } else {
                    imageUrl = imageUrl + '-/preview/150x150/';
                }

                $('#homingpinPhoto').attr('src', imageUrl);
                $('#removePhoto').show();
                $('#addPhoto').hide();
            },
            error: function (data) {

            }
        });
    });

    // Remove friend
    $('#removeAccess').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $(this).find('.friend-name').text(invoker.data('name'));
        $(this).find('input[name=friend_id]').val(invoker.data('id'))
    })

    /**
     * Add label
     */

    var addLabelWidget = uploadcare.Widget('#add-label-image-uploader');

    $('.uploadcare-widget').hide();

    $('#uploadAddLabelImage').click(function(e){
        e.preventDefault();
        var dialog = addLabelWidget.openDialog('file');
    });

    $('#removeAddLabelImage').click(function(e){
        e.preventDefault();
        $('#addLabelImageUUID').val('');
        $('#addLabelImage').removeAttr('src');
        $('#removeAddLabelImage').hide();
    });

    addLabelWidget.onUploadComplete(function(info) {
        // Handle uploaded file info.
        $('#addLabelImageUUID').val(info.uuid);
        $('#addLabelImageCrop').val(JSON.stringify(info.crop));

        var imageUrl = info.cdnUrl;

        if (imageUrl.match(/preview\/$/)) {
          imageUrl = imageUrl + '85x85/';
        } else {
          imageUrl = imageUrl + '-/preview/85x85/';
        }

        $('#addLabelImage').attr('src', imageUrl);
        $('#removeAddLabelImage').show();
        $('#addLabelImagePreview').val(imageUrl);
    });


    /**
     * Edit label
     */

    var editLabelWidget = uploadcare.Widget('#edit-label-image-uploader');

    $('.uploadcare-widget').hide();

    $('#uploadEditLabelImage').click(function(e){
        e.preventDefault();
        var dialog = editLabelWidget.openDialog('file');
    });

    $('#removeEditLabelImage').click(function(e){
        e.preventDefault();
        $('#editLabelImageUUID').val('');
        $('#editLabelImage').removeAttr('src');
        $('#removeEditLabelImage').hide();
    });

    editLabelWidget.onUploadComplete(function(info) {
        // Handle uploaded file info.
        $('#editLabelImageUUID').val(info.uuid);
        $('#editLabelImageCrop').val(JSON.stringify(info.crop));

        var imageUrl = info.cdnUrl;

        if (imageUrl.match(/preview\/$/)) {
          imageUrl = imageUrl + '85x85/';
        } else {
          imageUrl = imageUrl + '-/preview/85x85/';
        }

        $('#editLabelImage').attr('src', imageUrl);
        $('#removeEditLabelImage').show();
        $('#editLabelImagePreview').val(imageUrl);
    });

    $('#editLabel').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $(this).find('input[name=item_description]').val(invoker.data('description'));
        $(this).find('input[name=item_id]').val(invoker.data('id'));

        if (invoker.data('img') != '') {
            $(this).find('#editLabelImage').attr('src', invoker.data('img'));
            $('#removeEditLabelImage').show();
        } else {
            $(this).find('#editLabelImage').removeAttr('src');
            $('#removeEditLabelImage').hide();
        }
    });

    $('#deleteLabel').on('show.bs.modal', function (e) {
        var invoker = $(e.relatedTarget);

        $(this).find('.item_description').text(invoker.data('description'));
        $(this).find('input[name=item_id]').val(invoker.data('id'));
    });

});
