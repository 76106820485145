HomingPIN.addPage('admin_homingpins', function() {

    var dataTable = $('#adminHomingPins').DataTable({
        "pageLength": 10,
        "responsive": true,
        "language": {
            "paginate": {
              "previous": '<i class="fa fa-angle-left"></i>',
              "next": '<i class="fa fa-angle-right"></i>'
            }
        },
        "columnDefs": [
            { "name": "id",   "targets": 0 },
            { "name": "code",   "targets": 1 },
            { "name": "firstName",   "targets": 2 },
            { "name": "lastName",   "targets": 3 },
            { "name": "expiresAt",   "targets": 4, "render": $.fn.dataTable.render.moment( 'Do MMM YYYY' ) }
        ],
        "bProcessing": true,
        "bServerSide": true,
        "ajax": '/en/admin/homingpins.json'
    });

    $('#adminHomingPins').on('click', 'tbody tr', function(event) {
        var data = dataTable.row(this).data();
        document.location.href = '/en/admin/homingpins/' + data[1];
    });

});