// Page specific code

HomingPIN.addPage('activate_register', function() {

    $('#addHomingPinBtn').click(function(e){
        const source = $('#newHomingPin').html(),
                template = Hogan.compile(source),
                count = $('#homingpins').find('.form-group').length,
                data = {
                    index: (count + 1)
                },
                output = template.render(data);
        $('#homingpins').append(output);
    });

    $('#homingpins').on('click', '.btn-delete', function(event) {
        const target = '#' + $(this).data('row');
        $(target).remove();
    });
});
