// Page specific code

HomingPIN.addPage('account_index', function() {

    /* Homingpin Filters */

    // init Isotope
    var $grid = $('.grid').isotope({
      // options
    });
    // filter items on button click
    $('.homingpin-filters').on( 'click', 'button', function() {
      var filterValue = $(this).attr('data-filter');
      $grid.isotope({ filter: filterValue });
    });

    // THis is a foo comment

    $('.homingpin-filters').on('click', '.btn', function () {
        $(this).addClass('active').siblings().removeClass('active').addClass('');
    });

});



