HomingPIN.addPage('admin_customers', function() {

    var dataTable = $('#adminCustomers').DataTable({
        "pageLength": 10,
        "responsive": true,
        "language": {
            "paginate": {
              "previous": '<i class="fa fa-angle-left"></i>',
              "next": '<i class="fa fa-angle-right"></i>'
            }
        },
        "columnDefs": [
            { "name": "id",   "targets": 0 },
            { "name": "firstName",   "targets": 1},
            { "name": "lastName",   "targets": 2},
            { "name": "mobile",   "targets": 3},
            { "name": "email",   "targets": 4},
            { "name": "locale",   "targets": 5}
        ],
        "bProcessing": true,
        "bServerSide": true,
        "ajax": '/en/admin/customers.json'
    });

    $('#adminCustomers').on('click', 'tbody tr', function(event) {
        var data = dataTable.row(this).data();
        document.location.href = '/en/admin/customers/' + data.id;
    });

});