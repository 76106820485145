// Page specific code

HomingPIN.addPage('account_add_homingpins', function() {

    var checking = false;

    $(".homingpin-code").typeWatch({
        callback: function (value) {

            checking = true;

            var row = $(this).closest('.row');

            $.ajax({
                url: URL_PREFIX+'/account/homingpin/check-available',
                type: "post",
                dataType: "json",
                data: {
                    code: $(this).val()
                },
                success: function (data) {
                    if (data.success) {
                        row.find('.error-msg').hide();
                    } else {
                        row.find('.error-msg').text(data.msg).show();
                    }

                    checking = false
                },
                error: function (data) {
                    window.location.href = "/";
                }
            });

        },
        wait: 500,
        highlight: true,
        allowSubmit: false,
        captureLength: 6
    });

    $('#addHomingpins').submit(function(e) {
        if (checking) {
            e.preventDefault();
            return false;
        }
    });

});
