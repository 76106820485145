/**
 * Site-wide JS
 */

$(".open-nav").on('click touch', function () {
    $(".nav-overlay").addClass("active");
    $("body").addClass("no-scroll");
    $(".navigation.home").addClass("shift");
});

$(".close-nav").on('click touch', function () {
    $(".nav-overlay").removeClass("active");
    setTimeout(function() {
       $("body").removeClass("no-scroll");
       $(".navigation.home").removeClass("shift");
   }, 400);
});

$('.datatable').DataTable();

$('[data-toggle="popover"]').popover({
    html: true
});

$('input.confirmation').bind("cut copy paste",function(e) {
  e.preventDefault();
});

$('[data-toggle="tooltip"]').tooltip();

$('.datepicker').datepicker({
    format: 'yyyy-m-d'
});

setTimeout(function() {
    $(".notification.success").slideUp();
}, 4000);

// alert(COUNTRY_CODE);
// Telephone inputs
$(".telephone-number").intlTelInput({
    // defaultCountry: "auto",
    defaultCountry: COUNTRY_CODE.toLowerCase(),
    preferredCountries: ['gb','us'],
    nationalMode: true,
    utilsScript: "/js/vendor/intl-tel-input/js/utils.js"
});

$('.btn-unverified').click(function (e) {
    var url = $(this).attr('href'),
        tel = $(this).parent().prev();
    var country_code = $(tel).find('.country.active').data('country-code');
    if (country_code) {
      var newUrl = url + '&country_code=' + country_code;
      $(this).attr('href', newUrl);
    }
});

$("form").submit(function() {
    var numbers = $(this).find('.telephone-number');
    numbers.each(function(index, number){
        if ($(number).intlTelInput("getNumber")) {
            $(number).val($(number).intlTelInput("getNumber"));
        }
    });
});

// Account Menu
$.fn.toggleClick = function() {
    var methods = arguments;    // Store the passed arguments for future reference
    var count = methods.length; // Cache the number of methods

    // Use return this to maintain jQuery chainability
    // For each element you bind to
    return this.each(function(i, item){
        // Create a local counter for that element
        var index = 0;

        // Bind a click handler to that element
        $(item).on("click", function(e) {
            console.log(e);
            // That when called will apply the 'index'th method to that element
            // the index % count means that we constrain our iterator between 0
            // and (count-1)
            return methods[index++ % count].apply(this, arguments);
        });
    });
};

$(".account-menu").toggleClick(function() {
    $(".account-sm-menu").addClass("active");
}, function() {
    $(".account-sm-menu").removeClass("active");
});

$.getJSON('https://freegeoip.net/json/')
 .done (function(location)
 {
    var country = 'countries.' + location.country_name.replace(' ', '_').toLowerCase();
    var countrySelect = $('select[name="country"]');
    if (!countrySelect.val()) {
      $('select[name="country"]').val(country).selectpicker('refresh');
    }
 });


 // UMD
(function( factory ) {
    "use strict";
    if ( typeof define === 'function' && define.amd ) {
        // AMD
        define( ['jquery'], function ( $ ) {
            return factory( $, window, document );
        } );
    }
    else if ( typeof exports === 'object' ) {
        // CommonJS
        module.exports = function (root, $) {
            if ( ! root ) {
                root = window;
            }
            if ( ! $ ) {
                $ = typeof window !== 'undefined' ?
                    require('jquery') :
                    require('jquery')( root );
            }
            return factory( $, root, root.document );
        };
    }
    else {
        // Browser
        factory( jQuery, window, document );
    }
}

(function( $, window, document ) {
    $.fn.dataTable.render.moment = function ( from, to, locale ) {
        // Argument shifting
        if ( arguments.length === 1 ) {
            locale = 'en';
            to = from;
            from = 'YYYY-MM-DD';
        }
        else if ( arguments.length === 2 ) {
            locale = 'en';
        }
        return function ( d, type, row ) {
            var m = window.moment(d.date);
            // Order and type get a number value from Moment, everything else
            // sees the rendered value
            return m.format( type === 'sort' || type === 'type' ? 'x' : to );
        };
    };
}));
