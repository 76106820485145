HomingPIN.addPage('admin_code_ranges', function() {
    var dataTable = $('#adminCodeRanges').DataTable({
        "pageLength": 10,
        "responsive": true,
        "language": {
            "paginate": {
              "previous": '<i class="fa fa-angle-left"></i>',
              "next": '<i class="fa fa-angle-right"></i>'
            }
        },
        "columnDefs": [
            { "name": "id",   "targets": 0 },
            { "name": "rangeName",   "targets": 1},
            { "name": "prefix",   "targets": 2},
            { "name": "rangeStart",   "targets": 3},
            { "name": "rangeEnd",   "targets": 4},
            { "name": "type",   "targets": 5},
            { "name": "onlineRange",   "targets": 6},
            { "name": "retail",   "targets": 7},
            { "name": "trialPeriod",   "targets": 8}
        ],
        "bProcessing": true,
        "bServerSide": true,
        "ajax": '/en/admin/homingpins/code-ranges.json'
    });

    $('#adminCodeRanges').on('click', 'tbody tr', function(event) {
        var data = dataTable.row(this).data();
        document.location.href = '/en/admin/homingpins/code-ranges/' + data.id;
    });

});
